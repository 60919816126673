<template>
  <div class="special-new-wrapper">
    <header>
      <h3>{{ currentTabTitle }}</h3>
    </header>
    <main>
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                v-if="isSaveButtonVisible"
                type="button"
                :class="['btn', 'btn-success', { 'btn-disabled': isSaveButtonDisabled }]"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <ButtonClose route-name="themeSpecialAndSeries_list" />
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-12">
                    <header class="themeSpecialAndSeries-info-header">
                      <div>
                        <h6> {{ $t('tag.geneeaTag.generalInfo.createdAt') }}: </h6>
                        <span> {{ special.createdAt | prettyDateTime }} </span>
                      </div>
                      <div>
                        <h6> {{ $t('tag.geneeaTag.generalInfo.modifiedAt') }}: </h6>
                        <span> {{ special.modifiedAt | prettyDateTime }} </span>
                      </div>
                    </header>
                    <main>
                      <div class="row">
                        <!-- *** First col *** -->
                        <div class="col-lg-6 special-first-col">
                          General information
                          <div class="special-generalInfo">
                            <Checkbox
                              v-model="special.isActive"
                              id="special_isActive"
                              :label="$t('themeSpecialAndSeries.isActive')"
                              class="m-t-10 m-b-20"
                            />
                            <Checkbox
                              v-model="special.forChildSites"
                              id="special_all_sites_on"
                              :label="$t('themeSpecialAndSeries.specials.allSitesCheckboxLabel')"
                              class="m-t-10 m-b-20"
                            />

                            <SiteSelect
                              v-if="vlm"
                              v-model="special.siteId"
                              id="special_site_select"
                              class="mb-3"
                              optionTitle="title"
                              :options="sites"
                              :required="false"
                              :label="$t('themeSpecialAndSeries.specials.sitesInputLable') + ' *'"
                              :disabled="special.forChildSites"
                              @blur="$v.special.siteId.$touch()"
                              :error="$v.special.siteId.$error"
                            />

                            <SelectInput
                              v-else
                              v-model="special.siteId"
                              id="special_site_select"
                              optionTitle="title"
                              :options="sites"
                              :noEmptyValue="false"
                              :required="false"
                              :label="$t('themeSpecialAndSeries.specials.sitesInputLable') + ' *'"
                              :disabled="special.forChildSites"
                              @blur="$v.special.siteId.$touch()"
                              :error="$v.special.siteId.$error"
                            />
                            <Input
                              v-model="special.name"
                              :label="$t('themeSpecialAndSeries.specials.name') + ' *'"
                              @blur="$v.special.name.$touch()"
                              :error="$v.special.name.$error || formErrors.type === 'duplicate_name'"
                            />
                            <Input
                              v-if="editMode"
                              v-model="special.id"
                              label="Id"
                              disabled
                            />
                            <Input
                              v-model="special.slug"
                              :label="$t('themeSpecialAndSeries.specials.slug') + ' *'"
                              @blur="$v.special.slug.$touch()"
                              :error="$v.special.slug.$error"
                            />
                            <div class="title-text">
                              {{ $t('themeSpecialAndSeries.specials.description') }}
                            </div>
                            <RichTextEditor
                              v-model="special.description"
                              :other-options="richTextEditorConfig"
                              id="gspecial_description"
                              class="mb-3"
                            />
                            <Datepicker
                              type="date"
                              v-model="special.validTo"
                              id="special-validTo"
                              :label="$t('themeSpecialAndSeries.specials.validTo')"
                            />
                            <h3> {{ $t('themeSpecialAndSeries.specials.metaHeader') }} </h3>
                            <Input
                              v-if="special.meta"
                              v-model="special.meta.title"
                              :label="$t('themeSpecialAndSeries.specials.meta.title')"
                            />
                            <Input
                              v-if="special.meta"
                              v-model="special.meta.description"
                              :label="$t('themeSpecialAndSeries.specials.meta.description')"
                            />
                            <Input
                              v-if="special.meta"
                              v-model="special.meta.keywords"
                              :label="$t('themeSpecialAndSeries.specials.meta.keywords')"
                            />
                          </div>
                        </div>
                        <!-- *** Second col *** -->
                        <div class="col-lg-6 geenea-tag-second-col">
                          <!-- event -->
                          <div class="special-generalInfo">
                            <div class="title-text">
                              {{ $t('themeSpecialAndSeries.specials.heroImage') }}
                            </div>
                            <ModuleFormPhotoBox
                              :image="special.heroImage"
                              :media-usage-type="specialMediaTypes.hero"
                              hide-separator
                              @set-media="setSpecialheroImage"
                              @remove-media="removeSpecialheroImage"
                              class="mb-3"
                            />
                            <div class="title-text">
                              {{ $t('themeSpecialAndSeries.specials.listingImage') }}
                            </div>
                            <ModuleFormPhotoBox
                              :image="special.listingImage"
                              :media-usage-type="specialMediaTypes.listing"
                              hide-separator
                              @set-media="setSpecialListingImage"
                              @remove-media="removeSpecialListingImage"
                              class="mb-3"
                            />
                            <EditorialTagInput
                              :responseTags="special.tags"
                              :title="$t('themeSpecialAndSeries.tags')"
                              @selectedTagsIds="setTags"
                            />
                          </div>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'
import PermissionService from '@/services/PermissionService'
import NotifyService from '@/services/NotifyService'
import ButtonClose from '@/components/shared/ButtonClose'
import Checkbox from '@/components/form/Checkbox.vue'
import Input from '@/components/form/inputs/Input.vue'
import Datepicker from '@/components/form/Datepicker.vue'
import SelectInput from '@/components/form/select/Select.vue'
import EditorialTagInput from '@/components/EditorialTags/EditorialTagInput.vue'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox.vue'
import TinyMceConfig from '@/services/tinymce/TinyMceConfig'
import SpecialModel from '@/model/SpecialModel'
import TinyMceConfigVLMSettings from '@/model/TinyMceConfigVLMSettings'
import { MEDIA_USAGE_TYPE_SPECIAL_ARTICLE_GROUP_HERO, MEDIA_USAGE_TYPE_SPECIAL_ARTICLE_GROUP_LISTING } from '@/model/ValueObject/MediaUsageTypes'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import SiteSelect from '@/components/form/select/SiteSelect.vue'

const notZeroNullOrUndefined = value => value !== 0 && value !== null && value !== undefined

export default {
  name: 'SpecialNew',
  props: {
    editMode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SiteSelect,
    ButtonClose,
    Checkbox,
    Input,
    Datepicker,
    SelectInput,
    EditorialTagInput,
    RichTextEditor,
    ModuleFormPhotoBox
  },
  data () {
    return {
      special: this._.cloneDeep(SpecialModel),
      searchedTag: '',
      specialMediaType: '', // TODO create media type
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_TAG_PERMISSIONS,
      richTextEditorConfig: {
        ...TinyMceConfig.getConfig(), ...TinyMceConfigVLMSettings
      },
      specialMediaTypes: {
        hero: MEDIA_USAGE_TYPE_SPECIAL_ARTICLE_GROUP_HERO,
        listing: MEDIA_USAGE_TYPE_SPECIAL_ARTICLE_GROUP_LISTING
      }
    }
  },
  validations: {
    special: {
      siteId: {
        required,
        notZeroNullOrUndefined
      },
      slug: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(45)
      },
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(45)
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters('themeSpecialAndSeries', [
      'specialList',
      'specialTotalCount',
      'specialPage',
      'error',
      'formErrors',
      'specialDetail'
    ]),
    ...mapGetters('tabNavigator', [
      'currentTab',
      'currentTabTitle'
    ]),
    ...mapGetters(['vlm']),
    sites () {
      return this.$store.getters['site/allEnabledSorted']()
    },
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    },
    isSaveButtonDisabled () {
      return this.special.siteId === 0 && this.special.forChildSites === false
    },
    isDeleteButtonVisible () {
      return this.special.id !== null && this.hasPermission(this.requiredPermissions.deleteButton)
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.prepareDataForSave()
        if (this.editMode) {
          this.$store.dispatch('themeSpecialAndSeries/updateSpecial', this.special)
            .then(() => {
              if (!this.error) {
                NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
                this.special = this.specialDetail
                this.$router.push('/themeSpecialAndSeries')
              } else {
                NotifyService.setErrorMessage(this.error)
              }
            })
        } else {
          this.$store.dispatch('themeSpecialAndSeries/createSpecial', this.special)
            .then(() => {
              if (!this.error) {
                NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
                this.special = this.specialDetail
                // this.$router.push('/themeSpecialAndSeries/' + this.special.id + '/edit')
                this.$router.push('/themeSpecialAndSeries')
              } else {
                NotifyService.setErrorMessage(this.error)
              }
            })
            .catch(error => console.log(error))
        }
      }
    },
    setSpecialheroImage (image) {
      this.special.heroImage = image[0]
      this.special.heroImageId = image[0].id
    },
    removeSpecialheroImage () {
      this.special.heroImage = {}
      this.special.heroImageId = null
    },
    setSpecialListingImage (image) {
      this.special.listingImage = image[0]
      this.special.listingImageId = image[0].id
    },
    removeSpecialListingImage () {
      this.special.listingImage = {}
      this.special.listingImageId = null
    },
    setTags (tags) {
      this.special.tagIds = tags
    },
    deleteSpecial () {
      this.$store.dispatch('themeSpecialAndSeries/deleteSpecial', this.special)
        .then(() => {
          if (!this.error) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/themeSpecialAndSeries')
          } else {
            NotifyService.setErrorMessage(this.error)
          }
        })
        .catch(error => console.log(error))
    },
    prepareDataForSave () {
      if (this.special.heroImage && this.special.heroImage.id) {
        this.special.heroImageId = this.special.heroImage.id
      } else {
        this.special.heroImageId = null
      }
      if (this.special.listingImage && this.special.listingImage.id) {
        this.special.listingImageId = this.special.listingImage.id
      } else {
        this.special.listingImageId = null
      }
    }
  },
  watch: {
    specialDetail: {
      handler (newValue) {
        this.special = this.specialDetail
        if (this.special.tags) {
          this.special.tagIds = this.special.tags.map(tag => tag.id)
        }
      }
    },
    'special.forChildSites': {
      handler (newValue) {
        if (newValue) {
          this.special.siteId = 106 // Deník.cz
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.$store.commit('themeSpecialAndSeries/SET_FORM_ERRORS', {})
    if (this.editMode) {
      this.special = { ...this._.cloneDeep(SpecialModel), ...this.specialDetail }
      if (this.special.tags) {
        this.special.tagIds = this.special.tags.map(tag => tag.id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.themeSpecialAndSeries-info-header {
  margin: 1rem;
  text-align: right;
  div, h6 {
    display: inline;
  }
  span, h6 {
    margin-right: 1rem;
  }
  h6 {
    color: #bbb;
    font-weight: 100;
  }
}

.special-new-wrapper {
  button.btn-disabled {
    background-color: #8b8b8b;
    &:hover {
      background-color: #8b8b8b;
    }
  }
}

</style>
